import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { AuthService } from '@abp/ng.core';
import { Observable } from 'rxjs';
import { AppState } from '../../../app.state';
import { VendorDto } from '../../../proxy/vendors/models';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { AttachmentService } from '../../../proxy/common/attachment.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { VendorService } from '../../../proxy/vendors/vendor.service';
import { environment } from 'src/environments/environment';
import { ManageProfileVendorComponent } from 'src/app/account/manage-profile-vendor/manage-profile-vendor.component';
import { UserProfileDto } from 'src/app/proxy/users/models';
import { clearRenderedMenu } from 'src/app/menu.provider';

@Component({
  selector: 'app-vendor-layout',
  templateUrl: './vendor-layout.component.html',
  styleUrls: ['./vendor-layout.component.scss'],
})
export class VendorLayoutComponent implements OnInit {
  @Select(AppState.getUserProfile)
  userProfile$: Observable<UserProfileDto>;
  userProfile: UserProfileDto;

  loading = true;

  @Select(AppState.getVendor)
  vendor$: Observable<VendorDto[]>;
  vendor: VendorDto;

  id: string;

  entityName = 'vendor';
  previewUrl: string;

  supportMail: string;

  currentApplicationVersion = `v${environment.appVersion}`;

  constructor(
    private authService: AuthService,
    private nzDrawerService: NzDrawerService,
    private attachmentService: AttachmentService,
    private oAuthService: OAuthService,
    private vendorService: VendorService
  ) {
    if (this.isLoggedIn()) {
      this.userProfile$.subscribe(c => {
        this.userProfile = c;
      });
      this.vendor$.subscribe(u => {
        if (u) {
          this.vendor = u[0];
          this.id = this.vendor.id;
          this.getPicture();
          this.getSupportMail();
        }
      });
    } else {
      this.authService.initLogin();
    }
  }

  isLoggedIn() {
    return this.oAuthService.hasValidAccessToken();
  }

  ngOnInit(): void {}

  getPicture() {
    this.attachmentService
      .download(this.vendor.id, `${this.entityName}/picture/${this.vendor.id}`)
      .subscribe(s => {
        if (s && s.content) {
          this.previewUrl = `data:${s.type};base64,${s.content}`;
        }
      });
  }

  getSupportMail() {
    this.vendorService.getSupportMailByCountryCode(this.vendor.country.code).subscribe(s => {
      this.supportMail = s;
    });
  }

  logOut() {
    clearRenderedMenu();
    this.oAuthService.logOut();
    location.href = '/';
  }

  profileClick() {
    this.nzDrawerService.create<ManageProfileVendorComponent, any>({
      nzContent: ManageProfileVendorComponent,
      nzBodyStyle: {
        backgroundColor: '#f2f2f3',
      },
      nzWidth: '40%',
      nzContentParams: {},
      nzCloseOnNavigation: false,
    });
  }
}
