<a nz-button href="/" nzType="link" nzSize="large" nzBlock>
    <img class="logo" src="../assets/img/focali_logo_big.svg" alt="logo">
</a>
<nz-result nzStatus="404" nzTitle="404" nzSubTitle="Sorry, the page you visited does not exist.">
    <div nz-result-extra>
        <a nz-button href="/" nzType="link" nzSize="large" nzBlock>
            <i nz-icon nzType="home" nzTheme="twotone"></i>
            Home
        </a>
    </div>
</nz-result>