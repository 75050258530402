<div nz-row nzJustify="space-around" nzAlign="middle">
    <div nz-col nzFlex="400px">
        <nz-card *ngIf="todayWeather" [nzTitle]="header" [nzActions]="[actionSetting]">
            <div nz-row>
                <div nz-col [nzSpan]="24">
                    <h2 nz-typography>{{ port.name }}</h2>
                </div>
            </div>
            <div nz-row>
                <div nz-col [nzSpan]="24">
                    <!-- <span nz-typography>Code: {{ port.code }}</span> <br />
                </div>
                <div nz-col [nzSpan]="8">
                    <span nz-typography>UNLO Code: {{ port.unloCode }}</span>
                </div>
                <div nz-col [nzSpan]="8"> -->
                    <span nz-typography>{{ port.countryName }}</span>
                </div>
            </div>
            <div nz-row>
                <div nz-col [nzSpan]="12">
                    <nz-statistic [nzValue]="(todayWeather.waveHeight?.sg | number)!" [nzTitle]="'Wave Height (m)'"
                        [nzPrefix]="fawater"></nz-statistic>
                    <ng-template #fawater>
                        <i class="fas fa-water"></i>
                    </ng-template>
                </div>
                <div nz-col [nzSpan]="12">
                    <nz-statistic [nzValue]="(todayWeather.gust?.sg | number)!" [nzTitle]="'Wind Gust (m/s)'"
                        [nzPrefix]="thermometer"></nz-statistic>
                    <ng-template #thermometer>
                        <i class="fas fa-thermometer-half"></i>
                    </ng-template>
                </div>
            </div>
            <div nz-row>
                <div nz-col [nzSpan]="12">
                    <nz-statistic [nzValue]="(todayWeather.swellHeight?.sg | number)!" [nzTitle]="'Swell Direction'"
                        [nzPrefix]="swellDirectionArrow"></nz-statistic>
                    <ng-template #swellDirectionArrow>
                        <i nz-icon nzType="arrow-up" [nzRotate]="todayWeather.swellDirection?.sg"></i>
                    </ng-template>
                </div>
                <div nz-col [nzSpan]="12">
                    <nz-statistic [nzValue]="(todayWeather.windSpeed?.sg | number)!" [nzTitle]="'Wind Speed'"
                        [nzPrefix]="windDirectionArrow"></nz-statistic>
                    <ng-template #windDirectionArrow>
                        <i nz-icon nzType="arrow-up" [nzRotate]="todayWeather.windDirection?.sg"></i>
                    </ng-template>
                </div>
            </div>
        </nz-card>
        <ng-template #header>
            Current Weather
        </ng-template>
        <ng-template #actionSetting>
            <div nz-row>
                <div nz-col nzFlex="100px">
                    <button nz-button nzType="default" (click)="download()">
                        <i nz-icon nzType="file-pdf"></i>
                        Download
                    </button>
                </div>
                <div nz-col nzFlex="auto" style="text-align: right; padding-right: 5px;">
                    <i> Updated On {{ todayWeather.time | date:'medium' }}</i>
                </div>
            </div>
        </ng-template>
    </div>
    <div nz-col nzFlex="auto">
        <div class="map-container">
            <div *ngIf="port && src" class="map-frame">
                <iframe width="100%" height="100%" scrolling="no" frameborder="0" [src]="src">
                    Browser does not support embedded objects.
                </iframe>
            </div>
        </div>
    </div>
</div>
<div nz-row nzJustify="space-around" nzAlign="middle">
    <div nz-col [nzSpan]="24">
        <nz-tabset [nzAnimated]="{ inkBar: true, tabPane: false}">
            <nz-tab *ngFor="let tab of tabs" [nzTitle]="tab.name | date:'dd MMM' ">
                <nz-table #basicTable [nzData]="weatherViewModel[tab.name]" [nzFrontPagination]="true" [nzPageSize]="200"
                    nzHideOnSinglePage nzPaginationType="small">
                    <thead>
                        <tr>
                            <th nzAlign="center">Time</th>
                            <th nzAlign="center">Air Temperature (°C)</th>
                            <th nzAlign="center">Water Tempertaure (°C)</th>
                            <th nzAlign="center">Relative Humidity (%)</th>
                            <th nzAlign="center">Visibility (km)</th>
                            <th nzAlign="center">Wave Height (m)</th>
                            <th nzAlign="center">Wave Period (s)</th>
                            <th nzAlign="center">Swell Height (m)</th>
                            <th nzAlign="center">Swell Direction </th>
                            <th nzAlign="center">Swell Period (s)</th>
                            <th nzAlign="center">Wind Speed (m/s)</th>
                            <th nzAlign="center">Wind Gust (m/s)</th>
                            <th nzAlign="center">Wind Direction</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of basicTable.data">
                            <td nzAlign="center">{{ data.time | date:'HH:mm' }}</td>
                            <td nzAlign="center">{{data.airTemperature?.sg}}</td>
                            <td nzAlign="center">{{data.waterTemperature?.sg}}</td>
                            <td nzAlign="center">{{data.humidity?.sg}}</td>
                            <td nzAlign="center">{{data.visibility?.sg}}</td>
                            <td nzAlign="center">{{ data.waveHeight?.sg }}</td>
                            <td nzAlign="center">{{ data.wavePeriod?.sg }}</td>
                            <td nzAlign="center">{{data.swellHeight?.sg }}</td>
                            <td nzAlign="center"> <i nz-icon nzType="arrow-up" [nzRotate]="data.swellDirection?.sg"></i>
                            </td>
                            <td nzAlign="center">{{data.swellPeriod?.sg }}</td>
                            <td nzAlign="center">{{data.windSpeed?.sg}}</td>
                            <td nzAlign="center">{{data.gust?.sg}}</td>
                            <td nzAlign="center"> <i nz-icon nzType="arrow-up" [nzRotate]="data.windDirection?.sg"></i>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
        </nz-tabset>
    </div>
</div>

<!-- Template -->

<nz-modal [(nzVisible)]="isVisible" [nzStyle]="{ top: '20px',minhight:'1200px' }" *ngIf="weatherViewModel"
    nzTitle={{port?.name}} nzOkText="Download" nzCancelText="Cancel" (nzOnOk)="weatherDownload()"
    (nzOnCancel)="isVisible = false" nzWidth="640px">
    <ng-container *nzModalContent>
        <div id="WeatherReport" style="width:580px;">

            <table style="margin: 2px 5px 2px 15px;" cellspacing="0" cellpadding="0">
                <!-- Header -->
                <tr>
                    <td style="width: 100%;">
                        <img src="../assets/img/company-logo.png" alt="Focali" width="80%" />
                    </td>
                </tr>
            </table>
            <br />
            <table
                style="border-right: 1px solid #D3D3D3;border-left: 1px solid #D3D3D3;border-top: 1px solid #D3D3D3; font-size: 6pt; width: 99%;margin: 2px 5px 2px 15px;"
                cellspacing="0" cellpadding="0">
                <thead>
                    <tr style="border-bottom: 1px solid #D3D3D3;">
                        <th style="text-align: center;">Date Time</th>
                        <th colspan="2" style="text-align: center;border-left: 1px solid #D3D3D3;">Temperature</th>
                        <th style="text-align: center;border-left: 1px solid #D3D3D3;">Humidity</th>
                        <th style="text-align: center;border-left: 1px solid #D3D3D3;">Visibility (km)</th>
                        <th colspan="2" style="text-align: center;border-left: 1px solid #D3D3D3;">Wave</th>
                        <th colspan="2" style="text-align: center;border-left: 1px solid #D3D3D3;">Swell</th>
                        <th colspan="2" style="text-align: center;border-left: 1px solid #D3D3D3;">Wind</th>
                    </tr>
                    <tr style="border-bottom: 1px solid #D3D3D3;">
                        <th style="text-align: center;"></th>
                        <th style="text-align: center;border-left: 1px solid #D3D3D3;">Air</th>
                        <th style="text-align: center;border-left: 1px solid #D3D3D3;">Water</th>
                        <th style="text-align: center;border-left: 1px solid #D3D3D3;">Relative (%)</th>
                        <th style="text-align: center;border-left: 1px solid #D3D3D3;">Visibility (km)</th>
                        <th style="text-align: center;border-left: 1px solid #D3D3D3;">Height (m)</th>
                        <th style="text-align: center;border-left: 1px solid #D3D3D3;">Period (s)</th>
                        <th style="text-align: center;border-left: 1px solid #D3D3D3;">Height (m)</th>
                        <th style="text-align: center;border-left: 1px solid #D3D3D3;">Period (s)</th>
                        <th style="text-align: center;border-left: 1px solid #D3D3D3;">Speed (m/s)</th>
                        <th style="text-align: center;border-left: 1px solid #D3D3D3;">Wind Gust (m/s)</th>
                    </tr>
                </thead>
                <tbody *ngFor="let tab of tabs">
                    <tr *ngFor="let data of weatherViewModel[tab.name]">
                        <td style="text-align: center;">{{tab.name | date:'dd MMM'}} {{ data.time | date:'HH:mm' }}</td>
                        <td style="text-align: center;">{{data.airTemperature?.sg}}</td>
                        <td style="text-align: center;">{{data.waterTemperature?.sg}}</td>
                        <td style="text-align: center;">{{data.humidity?.sg}}</td>
                        <td style="text-align: center;">{{data.visibility?.sg}}</td>
                        <td style="text-align: center;">{{ data.waveHeight?.sg }}</td>
                        <td style="text-align: center;">{{ data.wavePeriod?.sg }}</td>
                        <td style="text-align: center;">{{data.swellHeight?.sg }}</td>
                        <td style="text-align: center;">{{data.swellPeriod?.sg }}</td>
                        <td style="text-align: center;">{{data.windSpeed?.sg}}</td>
                        <td style="text-align: center;">{{data.gust?.sg}}</td>
                    </tr>
                    <tr>
                        <td colSpan="11" style="border-bottom: 1px solid #D3D3D3;"><br></td>
                    </tr>
                </tbody>
            </table>


            <!-- <div *ngIf="multi" id="chartContainer" style="height: 400px;">
            <ngx-charts-line-chart #chart [view]="view" [scheme]="colorScheme" [legend]="legend" [roundDomains]='true'
                [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis" [yAxis]="yAxis"
                [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline" [results]="multi">
            </ngx-charts-line-chart>
        </div>       -->

            <div id="test" style="margin-left:15px;width:574px;">
                <canvas id="myChart"></canvas>
            </div>
        </div>
    </ng-container>
</nz-modal>
