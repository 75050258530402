import type { CreatePortPdaColumnDto, PortPdaColumnDto, UpdatePortPdaColumnDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { DataRequestDto, EntitySchemaDto, IdNameDto, PageRequestDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PortPdaColumnService {
  apiName = 'Default';

  create = (input: CreatePortPdaColumnDto) =>
    this.restService.request<any, PortPdaColumnDto>({
      method: 'POST',
      url: `/api/app/portPdaColumn`,
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/portPdaColumn/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, PortPdaColumnDto>({
      method: 'GET',
      url: `/api/app/portPdaColumn/${id}`,
    },
    { apiName: this.apiName });

  getCount = (input: PageRequestDto) =>
    this.restService.request<any, number>({
      method: 'GET',
      url: `/api/app/portPdaColumn/count`,
      params: { filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getDropDownOptions = (input: DataRequestDto) =>
    this.restService.request<any, PagedResultDto<IdNameDto>>({
      method: 'GET',
      url: `/api/app/portPdaColumn/dropDownOptions`,
      params: { select: input.select, filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getExpandById = (id: string) =>
    this.restService.request<any, PortPdaColumnDto[]>({
      method: 'GET',
      url: `/api/app/portPdaColumn/${id}/expand`,
    },
    { apiName: this.apiName });

  getExtraPropertiesById = (id: string) =>
    this.restService.request<any, Record<string, object>>({
      method: 'GET',
      url: `/api/app/portPdaColumn/${id}/extraProperties`,
    },
    { apiName: this.apiName });

  getIds = (input: DataRequestDto) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: `/api/app/portPdaColumn/ids`,
      params: { select: input.select, filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getList = (input: PageRequestDto) =>
    this.restService.request<any, PagedResultDto<PortPdaColumnDto>>({
      method: 'GET',
      url: `/api/app/portPdaColumn`,
      params: { filter: input.filter, onlyActive: input.onlyActive, additionalData: input.additionalData, ignoreMaxResultCount: input.ignoreMaxResultCount, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getOneExtraPropertiesByIdAndKey = (id: string, key: string) =>
    this.restService.request<any, object>({
      method: 'GET',
      url: `/api/app/portPdaColumn/${id}/oneExtraProperties`,
      params: { key: key },
    },
    { apiName: this.apiName });

  getSchema = () =>
    this.restService.request<any, EntitySchemaDto>({
      method: 'GET',
      url: `/api/app/portPdaColumn/schema`,
    },
    { apiName: this.apiName });

  getWithDetails = (id: string) =>
    this.restService.request<any, PortPdaColumnDto>({
      method: 'GET',
      url: `/api/app/portPdaColumn/${id}/withDetails`,
    },
    { apiName: this.apiName });

  setExtraPropertiesByIdAndKeyAndValue = (id: string, key: string, value: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/portPdaColumn/${id}/setExtraProperties`,
      params: { key: key, value: value },
    },
    { apiName: this.apiName });

  update = (id: string, input: UpdatePortPdaColumnDto) =>
    this.restService.request<any, PortPdaColumnDto>({
      method: 'PUT',
      url: `/api/app/portPdaColumn/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
