import { Component, Input, OnInit } from '@angular/core';
import { RoutesService, LocalizationService } from '@abp/ng.core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { addToVisited, menus, renderMenu } from 'src/app/menu.provider';
import { clearPageStates } from '../../../xui/x-page/states/xui.state';
import { AppMenuDto } from 'src/app/app.model';

@Component({
  selector: 'app-site-sider',
  templateUrl: './site-sider.component.html',
  styleUrls: ['./site-sider.component.scss'],
})
export class SiteSiderComponent implements OnInit {
  @Input() isCollapsed: boolean;
  menus = menus;

  constructor(
    public routes: RoutesService,
    private localizationService: LocalizationService,
    private nzDrawerRef: NzDrawerRef
  ) {}

  ngOnInit() {
    if (menus == null) {
      renderMenu(this.routes, this.localizationService);
    }
    this.menus = menus.filter(f => f.path || f.children?.length);
    setTimeout(() => {
      const selected = document.querySelector('.main-nav .ant-menu-item-selected');
      if (selected) {
        const order = Number(selected.parentElement.getAttribute('data-index'));
        this.menus = this.menus.map(m => {
          if (m.order === order) (m as any).open = true;
          return m;
        });
        selected.scrollIntoView(true);
      }
    });
  }

  onMenuItemClick(menu: AppMenuDto) {
    clearPageStates();
    this.nzDrawerRef.close();
    this.isCollapsed = true;
    addToVisited(menu.path);
  }
}
