<nz-alert nzType="info" nzMessage="Informational Notes"
    nzDescription="Additional description and informations about copywriting." nzShowIcon></nz-alert>
<nz-list [nzDataSource]="data" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
    <ng-template #item let-item>
        <nz-comment [nzAuthor]="item.author" [nzDatetime]="item.datetime">
            <nz-avatar nz-comment-avatar nzIcon="user" [nzSrc]="item.avatar"></nz-avatar>
            <nz-comment-content>
                <p>{{ item.content }}</p>
            </nz-comment-content>
            <nz-comment-action>Reply to</nz-comment-action>
        </nz-comment>
    </ng-template>
</nz-list>