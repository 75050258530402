import { AppStateDto, PageLayoutContent } from './app.model';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { CustomerService } from './proxy/customers/customer.service';
import { GetUserProfile, GetCustomer, GetVendor, GetUserAndGroups, GetPageLayout } from './app.actions';
import { UserProfileDto } from './proxy/users/models';
import { UserProfileService } from './proxy/users/user-profile.service';
import { CustomerDto } from './proxy/customers/models';

import { ConfigState, ApplicationConfiguration } from '@abp/ng.core';
import { Observable, Observer } from 'rxjs';
import { VendorDto } from './proxy/vendors/models';
import { VendorService } from '@proxy/vendors';
import { PageRequestDto } from '@proxy/models';
import { EntityContentDto, EntityContentService } from '@proxy/common';

@State<AppStateDto>({
  name: 'AppState',
  defaults: {
    customer: null,
    vendor: null,
    userProfile: null,
    usersAndGroups: null,
    pageLayoutContent: null
  } as AppStateDto,
})
@Injectable()
export class AppState {
  @Selector()
  static getUserProfile({ userProfile }: AppStateDto): UserProfileDto {
    return userProfile;
  }

  @Selector()
  static getCustomer({ customer }: AppStateDto): CustomerDto[] {
    return customer;
  }

  @Selector()
  static getVendor({ vendor }: AppStateDto): VendorDto[] {
    return vendor;
  }

  @Selector()
  static getUsersAndGroups({ usersAndGroups }: AppStateDto): UserProfileDto[] {
    return usersAndGroups;
  }

  @Selector()
  static getPageLayoutContent({ pageLayoutContent }: AppStateDto): EntityContentDto {
    return pageLayoutContent;
  }

  constructor(
    private userProfileService: UserProfileService,
    private customerService: CustomerService,
    private vendorService: VendorService,
    private entityContentService: EntityContentService,
    private store: Store
  ) { }

  @Action(GetUserProfile)
  getUserProfile({ patchState }: StateContext<AppStateDto>, { }: GetUserProfile) {
    const currentUser = this.store.selectSnapshot(
      ConfigState.getOne('currentUser')
    ) as ApplicationConfiguration.CurrentUser;
    return this.userProfileService.getByUserId(currentUser.id).pipe(
      tap(result => {
        return patchState({
          userProfile: result,
        });
      })
    );
  }

  @Action(GetCustomer)
  getCustomer({ patchState }: StateContext<AppStateDto>, { payload }: GetCustomer) {
    const storeSnapShot = this.store.snapshot();
    const customer = storeSnapShot?.AppState?.userProfile?.customer;
    if (customer) {
      return new Observable<CustomerDto[]>((observer: Observer<CustomerDto[]>) => {
        observer.next([customer]);
        observer.complete();
        return { unsubscribe() { } };
      }).pipe(
        tap(result => {
          return patchState({
            customer: result,
          });
        })
      );
    } else {
      return this.customerService.getExpandById(payload).pipe(
        tap(result => {
          return patchState({
            customer: result,
          });
        })
      );
    }
  }

  @Action(GetVendor)
  getVendor({ patchState }: StateContext<AppStateDto>, { payload }: GetVendor) {
    const storeSnapShot = this.store.snapshot();
    const vendor = storeSnapShot?.AppState?.userProfile?.vendor;
    if (vendor) {
      return new Observable<VendorDto[]>((observer: Observer<VendorDto[]>) => {
        observer.next([vendor]);
        observer.complete();
        return { unsubscribe() { } };
      }).pipe(
        tap(result => {
          return patchState({
            vendor: result,
          });
        })
      );
    } else {
      return this.vendorService.getExpandById(payload).pipe(
        tap(result => {
          return patchState({
            vendor: result,
          });
        })
      );
    }
  }

  @Action(GetUserAndGroups)
  getUsersAndGroups({ patchState }: StateContext<AppStateDto>, { }: GetUserAndGroups) {
    return this.userProfileService.getList({ maxResultCount: 250 } as PageRequestDto).pipe(
      tap(result => {
        return patchState({
          usersAndGroups: result.items,
        });
      })
    );
  }

  @Action(GetPageLayout)
  getPageLayout({ patchState }: StateContext<AppStateDto>, { }: GetPageLayout) {
    const currentUser = this.store.selectSnapshot(ConfigState.getOne('currentUser')) as ApplicationConfiguration.CurrentUser;
    return this.entityContentService.getList({
      filter: `type eq "page-layout/user" && entityId eq "${currentUser.id}"`,
      maxResultCount: 1
    } as PageRequestDto).pipe(
      tap(result => {
        if (result.totalCount) {
          const pageLayoutContent = result.items[0] as PageLayoutContent;
          pageLayoutContent.pageLayout = pageLayoutContent.content ? JSON.parse(pageLayoutContent.content) : {};
          return patchState({
            pageLayoutContent: pageLayoutContent
          });
        } else {
          return patchState({
            pageLayoutContent: null
          });
        }
      })
    );
  }
}
