import { CustomerDto } from './proxy/customers/models';
import { VendorDto } from './proxy/vendors/models';
import { UserProfileDto } from './proxy/users/models';
import { CustomColumn } from './xui/x-page/x-pg-model/x-pg.model';
import { EntityContentDto } from '@proxy/common';
export interface AppMenuDto {
  path: string;
  component?: any;
  children?: AppMenuDto[];
  crudAction?: CrudActionDto;
  columns?: string[];
  invisible?: boolean;
  requiredPolicy?: string;
  module?: string;
  name?: string;
  parentName?: string;
  iconClass?: string;
  features?: {
    attachments?: boolean | { single?: boolean };
    comments?: boolean;
    picture?: boolean;
  };
  actions?: AppMenuDto[];
  tabs?: AppMenuDto[];
  lookupFieldName?: string;
  lookupFieldCode?: string;
  parent?: AppMenuDto;
  autoGenerateField?: string;

  parentPropRef?: string;
  links?: AppMenuDto[];
  entity?: string;
  customColumns?: CustomColumn[];
}

export interface CrudActionDto {
  list?: string;
  create?: string;
  read?: string;
  update?: string;
  delete?: string;
}

export class CrudAction {
  static hidden = 'hidden';
}

export enum TableView {
  Classic = '0',
  Compact = '1',
  SuperCompact = '2'
}

export interface PageLayout {
  tableView: TableView;
  noAnimations: boolean;
  showRowNo: boolean;
  recordsPerPage: number;
}

export interface PageLayoutContent extends EntityContentDto {
  pageLayout?: PageLayout
}

export interface AppStateDto {
  customer: CustomerDto[];
  userProfile: UserProfileDto;
  vendor: VendorDto[];
  usersAndGroups: UserProfileDto[];
  pageLayoutContent: PageLayoutContent;
}

export const BUNKER_TOC_CODE = 'TOC1';
export const CARGO_TOC_CODE = 'TOC3';
export const FULLAGENCY_TOC_CODE = 'TOC4';
export const CTM_TOC_CODE = 'TOC13';
export const BOAT_TOC_CODE = 'TOC11';

export const SIS_INTERFACE_TYPE_CODE = 'SIS';
export const EYESHARE_INTERFACE_TYPE_CODE = 'EYESHARE';

export const HUSBANDRY_INTERFACE_GROUP_CODE = 'HUSBANDRY';
export const COMMERCIAL_INTERFACE_GROUP_CODE = 'COMMERCIAL';

export const UAE_COUNTRY_CODE = 'UAE';
export const SGP_COUNTRY_CODE = 'SGP';
