<nz-layout class="portal-layout">
    <app-portal-header style="box-shadow: none;"></app-portal-header>
    <nz-content>
        <nz-sider nzCollapsible *ngIf="!isCollapsed" [nzTrigger]="null" class="sidebar-main">
            <app-site-sider [(isCollapsed)]="isCollapsed"></app-site-sider>
        </nz-sider>
        <div class="portal-content">
            <router-outlet></router-outlet>
        </div>
    </nz-content>
</nz-layout>