import { Component, OnInit, Optional } from '@angular/core';
import { Select } from '@ngxs/store';
import { AppState } from '../../app.state';
import { Observable, Observer } from 'rxjs';
import { UserProfileDto } from '../../proxy/users/models';
import { AuthService, RestService } from '@abp/ng.core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { OAuthService } from 'angular-oauth2-oidc';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AttachmentService } from '../../proxy/common/attachment.service';
import { CreateAttachmentDto } from '../../proxy/attachments/models';
import { clearRenderedMenu } from 'src/app/menu.provider';

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.scss'],
})
export class ManageProfileComponent implements OnInit {
  @Select(AppState.getUserProfile)
  userProfile$: Observable<UserProfileDto>;
  userProfile: UserProfileDto;

  pictureLoading = true;
  fileList: NzUploadFile[] = [];
  previewImage: string | undefined = '';
  previewVisible = false;

  entityName = 'userpic';

  constructor(
    private authService: AuthService,
    private oAuthService: OAuthService,
    private nzMessageService: NzMessageService,
    private restService: RestService,
    private attachmentService: AttachmentService,
    @Optional() private nzDrawerRef: NzDrawerRef
  ) {
    this.userProfile$.subscribe(u => {
      if (u == null) return;
      this.userProfile = u;
      this.getPicture();
    });
  }

  ngOnInit() {}

  logOut() {
    this.nzDrawerRef.close();
    clearRenderedMenu();
    this.oAuthService.logOut();
    location.href = '/';
  }

  getPicture() {
    this.attachmentService
      .download(this.userProfile.userId, `${this.entityName}/picture/${this.userProfile.userId}`)
      .subscribe(s => {
        if (s && s.content) {
          this.fileList = [
            {
              uid: s.id,
              name: s.name,
              status: 'done',
              url: `data:${s.type};base64,${s.content}`,
            } as NzUploadFile,
          ];
        }

        this.pictureLoading = false;
      });
  }

  beforeUpload = (file: File) => {
    this.pictureLoading = true;
    return new Observable((observer: Observer<boolean>) => {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.nzMessageService.error('Image must smaller than 2MB!');
        observer.complete();
        return;
      }
      this.restService
        .request<any, string>(
          {
            method: 'POST',
            responseType: 'text',
            url: `/api/app/attachment/upload`,
            params: { entity: `${this.entityName}/picture`, fileName: this.userProfile.userId },
            body: this.generateFormData(file),
            reportProgress: true,
          },
          { apiName: 'Default' }
        )
        .subscribe(filePath => {
          this.attachmentService
            .create({
              entityTypeFullName: `${this.entityName}/picture`,
              isPublic: true,
              name: file.name,
              path: filePath,
              size: file.size,
              type: file.type,
              entityRecordId: this.userProfile.userId,
            } as CreateAttachmentDto)
            .subscribe(s => {
              this.getBase64(file, (img: string) => {
                this.fileList = [
                  {
                    uid: s.id,
                    name: s.name,
                    status: 'success',
                    url: img,
                  } as NzUploadFile,
                ];
                this.pictureLoading = false;
              });
            });
        });
      return;
    });
  };

  generateFormData(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  }

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result?.toString()));
    reader.readAsDataURL(img);
  }

  handlePreview = async (file: NzUploadFile) => {
    console.log(file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  download = (file: NzUploadFile) => {
    const link = document.createElement('a');
    link.download = `${file.name}`;
    link.href = file.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  delete = (file: NzUploadFile) =>
    new Observable<boolean>(obs => {
      this.attachmentService.delete(file.uid).subscribe(() => {
        obs.next(true);
      });
    });
}

function getBase64(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
