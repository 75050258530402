<ul nz-menu nzMode="inline" class="main-nav">
  <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menus }"></ng-container>
  <ng-template #menuTpl let-menus>
    <ng-container *ngFor="let menu of menus">
      <li *ngIf="!menu.children" [nzPaddingLeft]="menu.level * 24" nz-menu-item [(nzSelected)]="menu.selected"
        nzMatchRouter>
        <i nz-icon [nzType]="menu.iconClass" *ngIf="menu.iconClass"></i>
        <a (click)="onMenuItemClick(menu)" [routerLink]="menu.path">{{menu.name}}</a>
      </li>
      <li *ngIf="menu.children" nz-submenu [nzPaddingLeft]="menu.level * 24" [(nzOpen)]="menu.open"
        [nzTitle]="menu.name" [nzIcon]="menu.iconClass">
        <ul [attr.data-index]="menu.order">
          <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menu.children }"></ng-container>
        </ul>
      </li>
    </ng-container>
  </ng-template>
</ul>
<div style="height: 100px; "></div>