<div id="main">
    <div class="fof">
        <div class="icon-wrapper">
            <i class="fas fa-server"></i>
        </div>
        <h1>Some thing went wrong</h1>
        <hr />
        <a href="/">
            <h2>
                <i class="fas fa-sync"></i>
                Click here to refresh
            </h2>
        </a>
    </div>
</div>