import { Component, OnInit } from '@angular/core';
import { AuthService, ConfigState, ApplicationConfiguration } from '@abp/ng.core';
import { Router, ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { AppState } from '../../../app.state';
import { CustomerDto } from '../../../proxy/customers/models';
import { pluck } from 'rxjs/operators';
import { AttachmentService } from '../../../proxy/common/attachment.service';
import { UserProfileDto } from '../../../proxy/users/models';
import { GetCustomer } from '../../../app.actions';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ManageProfileComponent } from '@abp/ng.account';
@Component({
  selector: 'app-portal-header',
  templateUrl: './portal-header.component.html',
  styleUrls: ['./portal-header.component.scss'],
})
export class PortalHeaderComponent implements OnInit {
  @Select(ConfigState.getOne('currentUser'))
  currentUser$: Observable<ApplicationConfiguration.CurrentUser>;

  homePath = ['/', 'portal'];

  @Select(AppState.getUserProfile)
  userProfile$: Observable<UserProfileDto>;
  userProfile: UserProfileDto;

  @Select(AppState.getCustomer)
  customer$: Observable<CustomerDto[]>;
  customer: CustomerDto;
  id: string;

  entityName = 'customer';
  previewUrl: string;
  isMobileAppInfoVisible:boolean=false

  constructor(
    private authService: AuthService,
    private router: Router,
    activatedRoute: ActivatedRoute,
    private store: Store,
    private attachmentService: AttachmentService,
    private nzDrawerService: NzDrawerService
  ) {
    combineLatest([activatedRoute.params]).subscribe(() => {
      try {
        this.id = activatedRoute.snapshot.children[0].children[0].params.id || '';
      } catch (error) {}

      if (this.id) {
        this.customer$.subscribe(c => {
          if (c) {
            this.customer = c[0];
            this.getPicture();
          } else {
            this.store
              .dispatch(new GetCustomer(this.id))
              .pipe(pluck('AppState', 'customer'))
              .subscribe(customer => {
                this.customer = customer[0];
                this.homePath = [...this.homePath, this.id];
                this.getPicture();
              });
          }
        });
      } else {
        this.userProfile$.subscribe(u => {
          if (u) {
            this.userProfile = u;
            this.customer = u.customer;
            this.getPicture();
          }
        });
      }
    });
  }

  ngOnInit(): void {}

  logOut() {
    this.authService.logout().subscribe(() => {
      this.authService.initLogin();
    });
  }

  getPicture() {
    this.attachmentService
      .download(this.customer.id, `${this.entityName}/picture/${this.customer.id}`)
      .subscribe(s => {
        if (s && s.content) {
          this.previewUrl = `data:${s.type};base64,${s.content}`;
        }
      });
  }

  profileClick() {
    this.nzDrawerService.create<ManageProfileComponent, any>({
      nzTitle: 'Manage Profile',
      nzContent: ManageProfileComponent,
      nzMask: false,
      nzWidth: '75%',
      nzContentParams: {},
    });
  }
  mobileAppInfo() {
    this.isMobileAppInfoVisible=true;
  }
}
