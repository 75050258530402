import { Action, Selector, State, StateContext } from '@ngxs/store';
import { XuiPageStateDto } from '../models/xui.model';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { GetItem } from '../actions/xui.actions';
import { XPgService } from '../x-pg-service/x-pg.service';
import { ItemEntityDto } from '../x-pg-model/x-pg.model';
import { ABP } from '@abp/ng.core';

export type PageFilterState = {
  quickSearchValue: string;
  quickSearchMin: number;
  quickSearchMax: number;
  selectedFilter: string;
  page: number;
  colSizes: { [key: string]: number };
  selectedFilterItem: ABP.Option<string>;
  sort: { prop: string; dir: string };
  selectedId: string;
};

// Page Filter State
export let pageFilterStates = {} as { entityName: PageFilterState };
export function clearPageStates() {
  pageFilterStates = {} as { entityName: PageFilterState };
}

@State<XuiPageStateDto>({
  name: 'XuiPageState',
  defaults: { item: {} } as XuiPageStateDto,
})
@Injectable()
export class XuiPageState {
  @Selector()
  static getItem({ item }: XuiPageStateDto): ItemEntityDto[] {
    return item;
  }

  constructor(private xPgService: XPgService) {}

  @Action(GetItem)
  getItem({ patchState }: StateContext<XuiPageStateDto>, { entityName, id }: GetItem) {
    return this.xPgService.getExpandById(entityName, id).pipe(
      tap(item =>
        patchState({
          item,
        })
      )
    );
  }
}
