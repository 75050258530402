import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SiteLayoutComponent } from './core/layout/site-layout/site-layout.component';
import { PageLayoutComponent } from './core/layout/page-layout/page-layout.component';
import { NotfoundComponent } from './core/error/notfound/notfound.component';
import { PortalLayoutComponent } from './core/layout/portal-layout/portal-layout.component';
import { VendorLayoutComponent } from './core/layout/vendor-layout/vendor-layout.component';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./security/security.module').then(m => m.SecurityModule),
  },
  {
    path: '',
    component: SiteLayoutComponent,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'portal',
    component: PortalLayoutComponent,
    loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule),
  },
  {
    path: 'vendor',
    component: VendorLayoutComponent,
    loadChildren: () => import('./vendor/vendor.module').then(m => m.VendorModule),
  },
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      {
        path: 'x',
        component: PageLayoutComponent,
        loadChildren: () => import('./xui/xui.module').then(m => m.XuiModule),
      },
      {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
      },
      {
        path: 'error',
        children: [{ path: 'notfound', component: NotfoundComponent }],
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
      },
    ],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
  },
  { path: '**', component: NotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
