<ng-container *ngIf="isLoggedIn; else emptyLayout">
  <ng-container *ngIf="loading">
    <ng-container *ngIf="isCurrentlyLoggedIn(); else currentlyLoggedOut">
      <app-site-loader></app-site-loader>
      <router-outlet></router-outlet>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #emptyLayout>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #currentlyLoggedOut>
  <div class="currentlyLoggedOut">
    <nz-result
      nzStatus="403"
      nzTitle="Access denied!"
      nzSubTitle="Sorry, you are not authorized to access this page."
    >
      <div nz-result-extra>
        <a style="color: white" nz-button nzType="link" href="/">Please click here</a>
      </div>
    </nz-result>
  </div>
</ng-template>
