import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AccountConfigModule } from '@abp/ng.account/config';
import { CoreModule } from '@abp/ng.core';
import { IdentityConfigModule } from '@abp/ng.identity/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { TenantManagementConfigModule } from '@abp/ng.tenant-management/config';
import { ThemeBasicModule } from '@abp/ng.theme.basic';
import { ThemeSharedModule } from '@abp/ng.theme.shared';

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';

import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { NgxsModule } from '@ngxs/store';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { IconsProviderModule } from './icons-provider.module';

import { VALIDATION_BLUEPRINTS } from '@ngx-validate/core';
import { VALIDATION_ERROR_TEMPLATE } from '@ngx-validate/core';

import { SharedModule } from './shared/shared.module';
import { SiteLayoutComponent } from './core/layout/site-layout/site-layout.component';
import { SiteHeaderComponent } from './core/layout/site-header/site-header.component';
import { SiteSiderComponent } from './core/layout/site-sider/site-sider.component';
import { BreadcrumbComponent } from './core/layout/site-breadcrumb/site-breadcrumb.component';

import { PageLayoutComponent } from './core/layout/page-layout/page-layout.component';
import { NotfoundComponent } from './core/error/notfound/notfound.component';
import { SiteLoaderComponent } from './core/layout/site-loader/site-loader.component';
import { ValidationComponent } from './core/error/validation/validation.component';
import { GoogleSheetsDbService } from 'ng-google-sheets-db';
import { PortalLayoutComponent } from './core/layout/portal-layout/portal-layout.component';
import { PortalHeaderComponent } from './core/layout/portal-header/portal-header.component';
import { AppState } from './app.state';
import { VendorLayoutComponent } from './core/layout/vendor-layout/vendor-layout.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { ManageProfileComponent } from './account/manage-profile/manage-profile.component';
import { SupportComponent } from './core/support/support/support.component';
import { AppErrorHandler } from './core/error/error.handler';
import { NotificationsComponent } from './core/notifications/notifications.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { HttpErrorComponent } from './core/error/http-error/http-error.component';
import { ManageProfileVendorComponent } from './account/manage-profile-vendor/manage-profile-vendor.component';
import { TenantInterceptor } from './core/interceptor/tenant-interceptor';
import { AppearanceComponent } from './account/manage-profile/appearance/appearance.component';

registerLocaleData(en);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot({
      environment,
    }),
    ThemeBasicModule.forRoot(),
    ThemeSharedModule.forRoot(),
    AccountConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    TenantManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    NgxsModule.forRoot(),
    NgxsModule.forFeature([AppState]),
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    KeyboardShortcutsModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics.measurementId),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
  ],
  declarations: [
    AppComponent,
    SiteLayoutComponent,
    SiteHeaderComponent,
    SiteSiderComponent,
    PageLayoutComponent,
    BreadcrumbComponent,
    NotfoundComponent,
    SiteLoaderComponent,
    ValidationComponent,

    PortalLayoutComponent,
    PortalHeaderComponent,
    VendorLayoutComponent,
    ChangePasswordComponent,
    ManageProfileComponent,
    ManageProfileVendorComponent,
    SupportComponent,
    NotificationsComponent,

    HttpErrorComponent,
    AppearanceComponent
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    APP_ROUTE_PROVIDER,
    Title,
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: VALIDATION_BLUEPRINTS,
      useValue: {
        uniqueUsername: '::AlreadyExists[{{ name }}]',
      },
    },
    {
      provide: VALIDATION_ERROR_TEMPLATE,
      useValue: ValidationComponent,
    },
    GoogleSheetsDbService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
