<nz-layout class="app-site-background" *ngIf="userProfile?.userName">
    <nz-header nz-row nzJustify="space-between">
        <div nz-col>
            <nz-space nzSize="small">

                <nz-space-item>
                    <span class="logo">
                        <a href="/vendor">
                            <img class="big" src="../assets/img/focali_logo_big.svg" alt="logo">
                        </a>
                    </span>
                </nz-space-item>

                <nz-space-item>
                    <span class="logo">
                        <a *ngIf="previewUrl" [routerLink]="['/' , 'vendor']">
                            <img class="big" [src]="previewUrl" alt="logo">
                        </a>
                    </span>
                </nz-space-item>

                <nz-space-item *ngIf="vendor">
                    {{ vendor.name }}
                </nz-space-item>

            </nz-space>
        </div>

        <div nz-col>
        </div>

        <div nz-col class="top-right-icons">

            <a nz-button nzType="link" [routerLink]="['/', 'vendor']" nz-tooltip nzTooltipTitle="Home"
                nzTooltipPlacement="bottom">
                <i class="trigger" nz-icon nzType="home" nzTheme="twotone"></i>
            </a>

            <a nz-button nzType="link" [routerLink]="['/', 'vendor', 'vendorcontact']" nz-tooltip
                nzTooltipTitle="Contacts" nzTooltipPlacement="bottom">
                <i class="trigger" nz-icon nzType="contacts" nzTheme="twotone"></i>
            </a>

            <a nz-button nzType="link" href="mailto:{{supportMail}}" target="_blank" nz-tooltip nzTooltipTitle="Support"
                nzTooltipPlacement="bottom">
                <i class="trigger" nz-icon nzType="customer-service" nzTheme="twotone"></i>
            </a>

            <a (click)="profileClick()">
                <nz-avatar nzSize="small" [nzText]="userProfile.name.substring(0,1)"></nz-avatar>
                <span style="margin-left: 5px;" nz-typography [nzContent]="userProfile.name"></span>
            </a>

        </div>

        <span class="top-right-drop">
            <i nz-icon nzType="unordered-list" class="app-link" nz-dropdown [nzDropdownMenu]="siteActionsMenu"
                nzTrigger="click" nzOverlayClassName="top-right-drop"></i>
            <nz-dropdown-menu #siteActionsMenu="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item>
                        <nz-avatar nzSize="small" [nzText]="userProfile.name.substring(0,1)"></nz-avatar>
                        <span style="margin-left: 5px;" nz-typography [nzContent]="userProfile.name"></span>
                    </li>
                    <li nz-menu-item><i nz-icon nzType="poweroff"></i> Logout</li>
                </ul>
            </nz-dropdown-menu>
        </span>

    </nz-header>
    <div class="version" [innerText]="currentApplicationVersion"></div>

    <nz-content>
        <div class="portal-content">
            <router-outlet></router-outlet>
        </div>
    </nz-content>
</nz-layout>