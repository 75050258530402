export class GetUserProfile {
  static readonly type = '[Page] Get Customer User';
  constructor() {}
}

export class GetCustomer {
  static readonly type = '[Page] Get Customer';
  constructor(public payload?: string) {}
}

export class GetVendor {
  static readonly type = '[Page] Get Vendor';
  constructor(public payload?: string) {}
}

export class GetUserAndGroups {
  static readonly type = '[Page] Get User And Groups';
  constructor() {}
}

export class GetPageLayout {
  static readonly type = '[Page] Get PageLayout';
  constructor() {}
}
