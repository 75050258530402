import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  LocalizationService,
  AuthService,
  ReplaceableComponentsService,
  ConfigState,
  ApplicationConfiguration,
} from '@abp/ng.core';
import { OAuthService } from 'angular-oauth2-oidc';
import { eThemeBasicComponents } from '@abp/ng.theme.basic';
import { Store, Select } from '@ngxs/store';
import { GetUserProfile, GetCustomer, GetVendor, GetPageLayout } from './app.actions';
import { pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApplicationInsightsService } from './core/error/applicationinsights.service';
import { AppState } from './app.state';
import { PageLayoutContent } from './app.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Select(ConfigState.getOne('currentUser'))
  currentUser$: Observable<ApplicationConfiguration.CurrentUser>;
  currentUser: ApplicationConfiguration.CurrentUser;

  isLoggedIn: boolean;

  @Select(AppState.getPageLayoutContent)
  pageLayout$: Observable<PageLayoutContent>;
  loading: boolean;

  public constructor(
    applicationInsightsService: ApplicationInsightsService,
    private titleService: Title,
    private localizationService: LocalizationService,
    public authService: AuthService,
    private oAuthService: OAuthService,
    private replaceableComponents: ReplaceableComponentsService,
    private store: Store
  ) {
    this.isLoggedIn = this.isCurrentlyLoggedIn();
    this.replaceableComponents.add({
      component: null,
      key: eThemeBasicComponents.ApplicationLayout,
    });
    this.replaceableComponents.add({
      component: null,
      key: eThemeBasicComponents.AccountLayout,
    });

    this.currentUser$.subscribe(c => {
      if (c?.isAuthenticated) {
        applicationInsightsService.setUserId(c.userName);
        this.currentUser = c;
        if (this.currentUser?.id) {
          this.store
            .dispatch(new GetUserProfile())
            .pipe(pluck('AppState', 'userProfile'))
            .subscribe(u => {
              this.store.dispatch(new GetPageLayout())
                .pipe(pluck('AppState', 'pageLayoutContent'))
                .subscribe(s => { this.loading = true; });

              if (u?.customer) {
                this.store
                  .dispatch(new GetCustomer())
                  .pipe(pluck('AppState', 'customer'))
                  .subscribe(s => { });
              }
              if (u?.vendor) {
                this.store
                  .dispatch(new GetVendor())
                  .pipe(pluck('AppState', 'vendor'))
                  .subscribe(s => { });
              }
            });
        }
      } else {
        applicationInsightsService.clearUserId();
      }
    });

    this.pageLayout$.subscribe(s => {
      if (s) {
        const pageLayout = s.pageLayout;
        if (pageLayout.noAnimations) {
          document.body.classList.add("no-animations");
        } else {
          document.body.classList.remove("no-animations");
        }
      }
    });
  }

  isCurrentlyLoggedIn() {
    return this.oAuthService.hasValidAccessToken();
  }

  ngOnInit(): void {
    const appName = localStorage.getItem('tenant_AppName') || this.localizationService.instant('::AppName');
    this.titleService.setTitle(appName);
  }
}
