import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
  Sanitizer,
  SecurityContext,
} from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ConfigState, ApplicationConfiguration } from '@abp/ng.core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  @ViewChild('iframeCtrl') iframeCtrl: ElementRef;

  src: SafeResourceUrl;

  constructor(sanitizer: DomSanitizer, private drawerRef: NzDrawerRef<string>) {
    this.src = sanitizer.bypassSecurityTrustResourceUrl(
      `//speehive.com/support?v=${new Date().getTime()}`
    );
  }

  @Select(ConfigState.getOne('currentUser'))
  currentUser$: Observable<ApplicationConfiguration.CurrentUser>;
  currentUser: ApplicationConfiguration.CurrentUser;

  loading = true;
  feedbNameTxtField: HTMLInputElement;
  feedbEmailTxtField: HTMLInputElement;

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.data === 'get_user') {
      const currentUserObj = {
        userName: this.currentUser.userName,
        email: this.currentUser.email,
      };
      const currentUserObjStr = JSON.stringify(currentUserObj);
      this.iframeCtrl.nativeElement.contentWindow.postMessage(currentUserObjStr, '*');
    }

    if (event.data === 'completed') {
      this.drawerRef.close();
    }

    this.loading = false;
  }

  ngOnInit(): void {
    this.currentUser$.subscribe(c => {
      this.currentUser = c;
    });
  }
}
