import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { LocalizationService } from '@abp/ng.core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NzAutocompleteOptionComponent } from 'ng-zorro-antd/auto-complete';
import { AppState } from '../../../app.state';
import { AppMenuDto } from '../../../app.model';
import { RoutesService } from '@abp/ng.core';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ManageProfileComponent } from 'src/app/account/manage-profile/manage-profile.component';
import { SupportComponent } from '../../support/support/support.component';
import { SiteSiderComponent } from '../site-sider/site-sider.component';
import { NotificationsComponent } from '../../notifications/notifications.component';
import { environment } from 'src/environments/environment';
import { UserProfileDto } from '@proxy/users/models';
import { addToVisited, renderMenu, visibleMenus } from 'src/app/menu.provider';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
})
export class SiteHeaderComponent implements OnInit {
  currentApplicationVersion = `v${environment.appVersion}`;

  @Input() isCollapsed: boolean;
  @Output() isCollapsedChange = new EventEmitter<boolean>();

  @ViewChild('search') search: ElementRef;

  @Select(AppState.getUserProfile)
  userProfile$: Observable<UserProfileDto>;
  userProfile: UserProfileDto;

  searchText: string;

  allMenus: AppMenuDto[] = [];
  menus: AppMenuDto[] = [];

  shortcuts: ShortcutInput[] = [];
  isMobileAppInfoVisible: boolean = false
  logoUrl = '../assets/img/logo2.png';

  enableMobile = localStorage.getItem('tenant_disableMobile') !== "true";

  constructor(
    private router: Router,
    private localizationService: LocalizationService,
    public routes: RoutesService,
    private nzDrawerService: NzDrawerService
  ) {
    this.userProfile$.subscribe(u => {
      if (u == null) return;
      this.userProfile = u;
    });
    const tenant_logo = localStorage.getItem('tenant_logo');
    if (tenant_logo) {
      this.logoUrl = tenant_logo;
    }
  }

  ngOnInit() {
    this.shortcuts.push({
      key: 'ctrl + space',
      command: () => {
        this.search.nativeElement.focus();
      },
      preventDefault: true,
    });
  }

  setIsCollapsed(value: boolean) {
    this.isCollapsed = value;
    this.isCollapsedChange.emit(this.isCollapsed);
  }

  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    if (value) {
      this.menus = this.allMenus.filter(
        option => option.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else {
      this.populateLasVisited();
    }
  }

  onSelect(event: NzAutocompleteOptionComponent): void {
    this.setIsCollapsed(true);
    setTimeout(() => {
      this.searchText = '';
      this.menus = [];
    }, 1);

    const menu = event.nzValue as AppMenuDto;
    this.router.navigateByUrl(menu.path);
    addToVisited(menu.path);
  }

  onSelectNewWin(event: PointerEvent, menu: AppMenuDto) {
    event.preventDefault();
    event.stopImmediatePropagation();
    window.open(menu.path, '_blank');
    addToVisited(menu.path);
  }

  initSearch() {
    if (visibleMenus == null) renderMenu(this.routes, this.localizationService);
    const allMenus = visibleMenus.map(m => {
      m.name = this.localizationService.instant(m.name);
      return m;
    });

    this.allMenus = allMenus;
    this.populateLasVisited();
  }

  private populateLasVisited() {
    if (!this.searchText) {
      setTimeout(() => {
        const menuVisitedStr = localStorage.getItem('menuVisited');
        if (menuVisitedStr) {
          const menuVisited = JSON.parse(menuVisitedStr) as any[];
          this.menus = this.allMenus.filter(f => menuVisited.find(m => m.path === f.path));
        }
      }, 100);
    }
  }

  profileClick() {
    this.nzDrawerService.create<ManageProfileComponent, any>({
      nzContent: ManageProfileComponent,
      nzBodyStyle: {
        backgroundColor: '#f2f2f3',
      },
      nzWidth: '40%',
      nzContentParams: {},
      nzCloseOnNavigation: false,
    });
  }

  supportClick() {
    this.nzDrawerService.create<SupportComponent, any>({
      nzTitle: 'Submit Ticket',
      nzContent: SupportComponent,
      nzMask: false,
      nzWidth: '40%',
      nzContentParams: {},
    });
  }

  openMenu() {
    this.nzDrawerService.create<SiteSiderComponent, any>({
      nzTitle: 'Menu',
      nzContent: SiteSiderComponent,
      nzWidth: window.innerWidth < 800 ? '75%' : '25%',
      nzNoAnimation: true,
      nzMaskClosable: true,
      nzPlacement: 'left',
      nzContentParams: {},
    });
  }

  notifications() {
    this.nzDrawerService.create<NotificationsComponent, any>({
      nzTitle: 'Notifications',
      nzContent: NotificationsComponent,
      nzWidth: window.innerWidth < 800 ? '75%' : '25%',
      nzNoAnimation: true,
      nzMaskClosable: true,
      nzPlacement: 'right',
      nzContentParams: {},
    });
  }
  mobileAppInfo() {
    this.isMobileAppInfoVisible = true;
  }
}
