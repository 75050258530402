import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Select } from '@ngxs/store';
import { EntityContentService } from '@proxy/common';
import { UserProfileDto } from '@proxy/users';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { Store } from '@ngxs/store';
import { GetPageLayout } from 'src/app/app.actions';
import { pluck } from 'rxjs/operators';
import { PageLayout, PageLayoutContent, TableView } from 'src/app/app.model';

@Component({
  selector: 'app-appearance-form',
  templateUrl: './appearance.component.html',
  exportAs: 'abpChangePasswordForm',
})
export class AppearanceComponent implements OnInit {
  @Select(AppState.getPageLayoutContent)
  pageLayoutContent$: Observable<PageLayoutContent>;
  pageLayoutContent: PageLayoutContent;

  @Select(AppState.getUserProfile)
  userProfile$: Observable<UserProfileDto>;
  userProfile: UserProfileDto;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private entityContentService: EntityContentService,
    private store: Store,
  ) { }

  pageLayout: PageLayout;
  ngOnInit(): void {
    this.userProfile$.subscribe(u => {
      if (u == null) return;
      this.userProfile = u;
    });

    const snapshot = this.store.snapshot();
    const s = snapshot.AppState.pageLayoutContent as PageLayoutContent;
    this.pageLayoutContent = s;
    this.pageLayout = s?.pageLayout;
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group(
      {
        tableView: [this.pageLayout?.tableView || TableView.Classic],
        noAnimations: [this.pageLayout?.noAnimations],
        showRowNo: [this.pageLayout?.showRowNo],
        recordsPerPage: [this.pageLayout?.recordsPerPage || 50],
      }
    );

    this.form.valueChanges.subscribe(async s => {
      if (this.pageLayoutContent == null) {
        let pageLayoutContent = {
          type: `page-layout/user`,
          content: JSON.stringify(s),
          pageLayout: s,
          entityId: this.userProfile.userId
        };
        await this.entityContentService.create(pageLayoutContent).toPromise();
      } else {
        this.pageLayoutContent.content = JSON.stringify(s);
        this.pageLayoutContent.pageLayout = s;
        await this.entityContentService.update(this.pageLayoutContent.id, this.pageLayoutContent).toPromise();
      }

      this.store
        .dispatch(new GetPageLayout())
        .pipe(pluck('AppState', 'pageLayoutContent'))
        .subscribe(s => {
          this.pageLayoutContent = s;
        });
    });
  }

  async resetToDefaults() {
    await this.entityContentService.delete(this.pageLayoutContent.id).toPromise();
    this.store
      .dispatch(new GetPageLayout())
      .pipe(pluck('AppState', 'pageLayoutContent'))
      .subscribe(s => {
        this.pageLayoutContent = null;
        this.pageLayout = null;
        this.buildForm();
      });
  }
}
