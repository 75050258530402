import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VesselService } from '@proxy/vessels';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './view-location.component.html',
  styleUrls: ['./view-location.component.scss'],
})
export class ViewLocationComponent implements OnInit {
  src: SafeResourceUrl;
  constructor(
    private activatedRoute: ActivatedRoute,
    private vesselService: VesselService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      const params = this.activatedRoute.snapshot.parent.params;
      this.vesselService.get(params.id).subscribe(s => {
        const vessel = s;
        const url = `//www.marinetraffic.com/en/ais/embed/zoom:9/maptype:0/shownames:true/mmsi:${
          vessel.mmsi || 0
        }/shipid:0/fleet:/fleet_id:/vtypes:/showmenu:false/remember:true`;
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      });
    });
  }
}
