import {
  ErrorHandler,
  Injector,
  Injectable,
  RendererFactory2,
  ComponentRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
} from '@angular/core';
import { ApplicationInsightsService } from './applicationinsights.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Config } from '@abp/ng.core';
import { ErrorScreenErrorCodes } from '@abp/ng.theme.shared';
import { HttpErrorComponent } from './http-error/http-error.component';
import { HttpErrorResponse } from '@angular/common/http';

export const DEFAULT_ERROR_MESSAGES = {
  defaultError: {
    title: 'An error has occurred!',
    details: 'Error detail not sent by server.',
  },
  defaultError401: {
    title: 'You are not authenticated!',
    details: 'You should be authenticated (sign in) in order to perform this operation.',
  },
  defaultError403: {
    title: 'You are not authorized!',
    details: 'You are not allowed to perform this operation.',
  },
  defaultError404: {
    title: 'Resource not found!',
    details: 'The resource requested could not found on the server.',
  },
  defaultError500: {
    title: 'Internal server error',
    details: 'Error detail not sent by server.',
  },
};

export const DEFAULT_ERROR_LOCALIZATIONS = {
  defaultError: {
    title: 'AbpUi::DefaultErrorMessage',
    details: 'AbpUi::DefaultErrorMessageDetail',
  },
  defaultError401: {
    title: 'AbpUi::DefaultErrorMessage401',
    details: 'AbpUi::DefaultErrorMessage401Detail',
  },
  defaultError403: {
    title: 'AbpUi::DefaultErrorMessage403',
    details: 'AbpUi::DefaultErrorMessage403Detail',
  },
  defaultError404: {
    title: 'AbpUi::DefaultErrorMessage404',
    details: 'AbpUi::DefaultErrorMessage404Detail',
  },
  defaultError500: {
    title: 'AbpUi::500Message',
    details: 'AbpUi::DefaultErrorMessage',
  },
};

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  componentRef: ComponentRef<HttpErrorComponent>;

  constructor(
    private injector: Injector,
    private notification: NzNotificationService,
    private rendererFactory: RendererFactory2,
    private cfRes: ComponentFactoryResolver
  ) {}

  navigateToLogin() {
    console.log('navigateToLogin');
  }

  handleError(err: HttpErrorResponse): void {
    console.log(err);

    this.injector.get<ApplicationInsightsService>(ApplicationInsightsService).logException(err);

    if (err.status == null) return;

    if (err?.error?.error?.message) {
      this.notification.error(err.error.error.message, err.error.error.details, {
        nzDuration: 10000,
      });
    } else {
      switch (err.status) {
        case 401:
          this.canCreateCustomError(401)
            ? this.show401Page()
            : this.showError(
                {
                  key: DEFAULT_ERROR_LOCALIZATIONS.defaultError401.title,
                  defaultValue: DEFAULT_ERROR_MESSAGES.defaultError401.title,
                },
                {
                  key: DEFAULT_ERROR_LOCALIZATIONS.defaultError401.details,
                  defaultValue: DEFAULT_ERROR_MESSAGES.defaultError401.details,
                }
              ).onClose.subscribe(() => this.navigateToLogin());
          break;
        case 403:
          this.createErrorComponent({
            title: {
              key: DEFAULT_ERROR_LOCALIZATIONS.defaultError403.title,
              defaultValue: DEFAULT_ERROR_MESSAGES.defaultError403.title,
            },
            details: {
              key: DEFAULT_ERROR_LOCALIZATIONS.defaultError403.details,
              defaultValue: DEFAULT_ERROR_MESSAGES.defaultError403.details,
            },
            status: 403,
          });
          break;
        case 404:
          this.canCreateCustomError(404)
            ? this.show404Page()
            : this.showError(
                {
                  key: DEFAULT_ERROR_LOCALIZATIONS.defaultError404.details,
                  defaultValue: DEFAULT_ERROR_MESSAGES.defaultError404.details,
                },
                {
                  key: DEFAULT_ERROR_LOCALIZATIONS.defaultError404.title,
                  defaultValue: DEFAULT_ERROR_MESSAGES.defaultError404.title,
                }
              );
          break;
        case 500:
          this.createErrorComponent({
            title: {
              key: DEFAULT_ERROR_LOCALIZATIONS.defaultError500.title,
              defaultValue: DEFAULT_ERROR_MESSAGES.defaultError500.title,
            },
            details: {
              key: DEFAULT_ERROR_LOCALIZATIONS.defaultError500.details,
              defaultValue: DEFAULT_ERROR_MESSAGES.defaultError500.details,
            },
            status: 500,
          });
          break;
        case 0:
          if (err.statusText === 'Unknown Error') {
            this.createErrorComponent({
              title: {
                key: DEFAULT_ERROR_LOCALIZATIONS.defaultError.title,
                defaultValue: DEFAULT_ERROR_MESSAGES.defaultError.title,
              },
              details: err.message,
              isHomeShow: false,
            });
          }
          break;
        default:
          this.showError(
            {
              key: DEFAULT_ERROR_LOCALIZATIONS.defaultError.details,
              defaultValue: DEFAULT_ERROR_MESSAGES.defaultError.details,
            },
            {
              key: DEFAULT_ERROR_LOCALIZATIONS.defaultError.title,
              defaultValue: DEFAULT_ERROR_MESSAGES.defaultError.title,
            }
          );
          break;
      }
    }
  }

  canCreateCustomError(status: ErrorScreenErrorCodes): boolean {
    // return snq(
    //   () =>
    //     this.httpErrorConfig.errorScreen.component &&
    //     this.httpErrorConfig.errorScreen.forWhichErrors.indexOf(status) > -1
    // );
    return false;
  }

  private showError(
    message?: Config.LocalizationParam,
    title?: Config.LocalizationParam,
    body?: any
  ) {
    if (body) {
      if (body.details) {
        message = body.details;
        title = body.message;
      } else if (body.message) {
        title = {
          key: DEFAULT_ERROR_LOCALIZATIONS.defaultError.title,
          defaultValue: DEFAULT_ERROR_MESSAGES.defaultError.title,
        };
        message = body.message;
      } else {
        message = body.message || {
          key: DEFAULT_ERROR_LOCALIZATIONS.defaultError.title,
          defaultValue: DEFAULT_ERROR_MESSAGES.defaultError.title,
        };
      }
    }

    const titleDefaultValue = (title as any)?.defaultValue || title;
    const messageDefaultValue = (message as any)?.defaultValue || message;
    return this.notification.error(titleDefaultValue, messageDefaultValue, {
      nzDuration: 5000,
    });
  }

  private show401Page() {
    this.createErrorComponent({
      title: {
        key: DEFAULT_ERROR_LOCALIZATIONS.defaultError401.title,
        defaultValue: DEFAULT_ERROR_MESSAGES.defaultError401.title,
      },
      status: 401,
    });
  }

  private show404Page() {
    this.createErrorComponent({
      title: {
        key: DEFAULT_ERROR_LOCALIZATIONS.defaultError404.title,
        defaultValue: DEFAULT_ERROR_MESSAGES.defaultError404.title,
      },
      status: 404,
    });
  }

  createErrorComponent(instance: Partial<HttpErrorComponent>) {
    const elementExist = document.body.getElementsByTagName('app-http-error');
    if (elementExist?.length) return;

    const renderer = this.rendererFactory.createRenderer(null, null);
    const host = renderer.selectRootElement(document.getElementsByTagName('app-root')[0], true);
    this.componentRef = this.cfRes
      .resolveComponentFactory(HttpErrorComponent)
      .create(this.injector);

    renderer.appendChild(host, (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0]);
  }
}
