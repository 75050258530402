<ng-container *ngIf="isLoggedIn()">
    <ng-container *ngIf="currentUser?.userName">
        <nz-layout class="app-site-background" *ngIf="currentUser?.emailVerified; else notConfirmed">
            <app-site-header [(isCollapsed)]="isCollapsed"></app-site-header>
            <nz-content>
                <div class="page-content">
                    <router-outlet></router-outlet>
                </div>
            </nz-content>
        </nz-layout>
    </ng-container>
</ng-container>

<ng-template #unauthorized>
    <div nz-row>
        <div nz-col nzOffset="8" nzSpan="16">
            <nz-result [nzIcon]="'smile-twotone'" nzStatus="403" [nzTitle]="'Un authorized!'">
                <div nz-result-extra>
                    Sorry, you are not authorized to access this page.
                    <button nz-button nzType="primary" (click)="logOut()">LogOut</button>
                </div>
            </nz-result>
        </div>
    </div>
</ng-template>

<ng-template #notConfirmed>
    <div nz-row>
        <div nz-col nzSpan="4"></div>
        <div nz-col nzSpan="16" style="background-color: aliceblue;">
            <nz-result [nzIcon]="'smile-twotone'" [nzTitle]="'Confirm your email address!'">
                <div nz-result-extra>
                    We have sent an email with a confirmation link to your email address. In order to complete the
                    sign-up
                    process,
                    please click the confirmation link.

                    If you do not receive a confirmation email, please check your spam folder. Also, please verify that
                    you
                    entered a
                    valid email address in our sign-up form.

                    If you need assistance, please contact us. <br />
                    <button nz-button nzType="primary" (click)="logOut()">Logout</button>
                </div>
            </nz-result>
        </div>
        <div nz-col nzSpan="4"></div>
    </div>
</ng-template>