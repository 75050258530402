<form
  nz-form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  [mapErrorsFn]="mapErrorsFn"
  validateOnSubmit
>
  <nz-form-item *ngIf="!hideCurrentPassword">
    <nz-form-label [nzSpan]="6" nzFor="current-password" nzRequired>Current</nz-form-label>
    <nz-form-control [nzSpan]="14">
      <nz-input-group [nzPrefix]="oldKey">
        <input
          type="password"
          nz-input
          id="current-password"
          formControlName="password"
          autocomplete="current-password"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzFor="new-password" nzRequired>New</nz-form-label>
    <nz-form-control [nzSpan]="14">
      <nz-input-group [nzPrefix]="key">
        <input
          type="password"
          nz-input
          id="new-password"
          formControlName="newPassword"
          autocomplete="new-password"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzFor="confirm-new-password" nzRequired>Confirm</nz-form-label>
    <nz-form-control [nzSpan]="14">
      <nz-input-group [nzPrefix]="key">
        <input
          type="password"
          nz-input
          id="confirm-new-password"
          formControlName="repeatNewPassword"
          autocomplete="new-password"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control nzOffset="6" [nzSpan]="14">
      <button nz-button nzType="primary" [nzLoading]="inProgress" [disabled]="form?.invalid">
        <i nz-icon nzType="check"></i>
        Change Password
      </button>
    </nz-form-control>
  </nz-form-item>
</form>

<ng-template #key>
  <i class="fa fa-key" aria-hidden="true"></i>
</ng-template>

<ng-template #oldKey>
  <i style="color: #bebeca" class="fa fa-key" aria-hidden="true"></i>
</ng-template>
