<form nz-form [formGroup]="form">
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzFor="tableView">Table View</nz-form-label>
    <nz-form-control [nzSpan]="14">
      <nz-radio-group formControlName="tableView" nzButtonStyle="solid">
        <label nz-radio-button nzValue="0">
          <i nz-icon nzType="bars" nzTheme="outline" style="font-size: 1rem"></i>
          Classic
        </label>
        <label nz-radio-button nzValue="1">
          <i nz-icon nzType="table" nzTheme="outline" style="font-size: 1rem"></i>
          Compact
        </label>
        <label nz-radio-button nzValue="2">
          <i nz-icon nzType="barcode" nzTheme="outline" nzRotate="90" style="font-size: 1rem"></i>
          Super Compact
        </label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzFor="noAnimations">Disable Animations</nz-form-label>
    <nz-form-control [nzSpan]="14">
      <nz-switch formControlName="noAnimations"></nz-switch>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzFor="showRowNo">Show Row Numbers</nz-form-label>
    <nz-form-control [nzSpan]="14">
      <nz-switch formControlName="showRowNo"></nz-switch>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzFor="recordsPerPage">Records Per Page</nz-form-label>
    <nz-form-control [nzSpan]="14">
      <nz-select formControlName="recordsPerPage" nzDropdownClassName="fit-dropdown-size">
        <nz-option [nzValue]="50" nzLabel="50 records"></nz-option>
        <nz-option [nzValue]="100" nzLabel="100 records"></nz-option>
        <nz-option [nzValue]="200" nzLabel="200 records"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control nzOffset="6" [nzSpan]="14">
      <button nz-button (click)="resetToDefaults()" [disabled]="pageLayoutContent?.id == null">
        <i nz-icon nzType="undo" nzTheme="outline"></i>
        Reset defaults
      </button>
    </nz-form-control>
  </nz-form-item>
</form>
