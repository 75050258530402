import { AppMenuDto } from './app.model';
// defaultModule : 'pages'
export const mappingMenus = [
  {
    path: 'tariff',
    iconClass: 'audit',
    lookupFieldName: 'portName',
    lookupFieldCode: 'typeOfCallName',
    features: { attachments: { single: true }, comments: true },
    crudAction: {
      list: 'pages/tariff',
      read: 'pages/tariff',
      create: 'pages/tariff',
      update: 'pages/tariff',
    },
    requiredPolicy: 'Mappings.Tariffs',
  },

  {
    path: 'ctmtariff',
    entity: 'customertariff',
    iconClass: 'dollar',
    lookupFieldName: 'customerName',
    lookupFieldCode: 'portName',
    features: { attachments: { single: true }, comments: true },
    crudAction: {
      list: 'pages/ctmtariff',
      read: 'pages/ctmtariff',
      create: 'pages/ctmtariff',
      update: 'pages/ctmtariff',
    },
    requiredPolicy: 'Mappings.Tariffs',
  },

  {
    path: 'customertariff',
    iconClass: 'team',
    lookupFieldName: 'customerName',
    lookupFieldCode: 'portName',
    features: { attachments: { single: true }, comments: true },
    crudAction: {
      list: 'pages/customertariff',
      read: 'pages/customertariff',
      create: 'pages/customertariff',
      update: 'pages/customertariff',
    },
    requiredPolicy: 'Mappings.Tariffs',
  },

  {
    path: 'portservicesection',
    lookupFieldName: 'portName',
    lookupFieldCode: 'typeOfCallName',
    columns: ['portName', 'typeOfCallName'],
    requiredPolicy: 'Mappings.TaskMappings',
    crudAction: {
      list: 'pages/portservicesection',
      read: 'pages/portservicesection',
      create: 'pages/portservicesection',
      update: 'pages/portservicesection',
    },
  },

  {
    path: 'configuringsop',
    lookupFieldName: 'portName',
    lookupFieldCode: 'typeOfCallName',
    columns: ['portName', 'typeOfCallName'],
    requiredPolicy: 'Mappings.ConfiguringSOPs',
    crudAction: {
      list: 'pages/configuringsop',
      read: 'pages/configuringsop',
      create: 'pages/configuringsop',
      update: 'pages/configuringsop',
    },
  },
] as AppMenuDto[];
