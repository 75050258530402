import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { ThemeBasicModule } from '@abp/ng.theme.basic';
import { ThemeSharedModule, ErrorHandler } from '@abp/ng.theme.shared';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { NgZorroAntdModule } from './ng-zorro-antd.module';
import { SelectOnFocusDirective } from '../core/directive/select-on-focus.directive';
import { NumberToWordsPipe } from '../core/pipe/number-to-words.pipe';
import { AppErrorHandler } from '../core/error/error.handler';
import { FormatDistancePipe } from '../core/pipe/format-distance';

@NgModule({
  declarations: [SelectOnFocusDirective, NumberToWordsPipe, FormatDistancePipe],
  imports: [
    CoreModule,
    ThemeSharedModule,
    ThemeBasicModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    NgZorroAntdModule,
  ],
  exports: [
    CoreModule,
    ThemeSharedModule,
    ThemeBasicModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    NgZorroAntdModule,
    SelectOnFocusDirective,
    NumberToWordsPipe,
    FormatDistancePipe,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
  ],
})
export class SharedModule {}
