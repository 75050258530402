import { Component, OnInit } from '@angular/core';
import { ErrorScreenErrorCodes } from '@abp/ng.theme.shared';
import { Config, SubscriptionService } from '@abp/ng.core';

@Component({
  selector: 'app-http-error',
  templateUrl: './http-error.component.html',
  styleUrls: ['./http-error.component.scss'],
})
export class HttpErrorComponent implements OnInit {
  statusCode: 404;

  status: ErrorScreenErrorCodes;

  title: Config.LocalizationParam;

  details: Config.LocalizationParam;

  isHomeShow: boolean;

  constructor() {}

  ngOnInit(): void {}
}
