<div nz-row>
    <div nz-col [nzSpan]="8" style="text-align: center;">
        <nz-space nzDirection="vertical" nzAlign="center">
            <nz-space-item>
                <!-- <nz-avatar *ngIf="fileList?.length" [nzSize]="64" [nzText]="userProfile.name.substring(0,1)"></nz-avatar> -->

                <nz-spin [nzSpinning]="pictureLoading">
                    <nz-upload class="avatar-uploader" nzListType="picture-card" [(nzFileList)]="fileList"
                        [nzBeforeUpload]="beforeUpload" [nzShowButton]="fileList.length < 1" [nzRemove]="delete"
                        [nzPreview]="handlePreview" [nzDownload]="download" nzAccept="image/*">
                        <div nz-tooltip nzTooltipTitle="Click to upload!" nzTooltipPlacement="bottom">
                            <nz-avatar *ngIf="!pictureLoading && !fileList?.length" [nzSize]="64" nzIcon="bank">
                            </nz-avatar>
                        </div>
                    </nz-upload>
                </nz-spin>

                <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                    (nzOnCancel)="previewVisible = false">
                    <ng-template #modalContent>
                        <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                    </ng-template>
                </nz-modal>

            </nz-space-item>
            <nz-form-item>
                <a nz-button nzType="link" style="font-size: 14px;" (click)="logOut()">
                    <i class="app-link" nz-icon nzType="poweroff"></i>
                    Logout
                </a>
            </nz-form-item>
        </nz-space>
    </div>
    <div nz-col [nzSpan]="16">
        <nz-space nzDirection="vertical">
            <nz-space-item>
                <h5 nz-typography [nzContent]="userProfile.name"></h5>
                <i nz-icon nzType="bank" nzTheme="twotone"></i>
                <span nz-typography [nzContent]="userProfile.vendor?.name"></span>
            </nz-space-item>
            <nz-space-item>
                <i nz-icon nzType="mail" nzTheme="twotone"></i>
                <span nz-typography [nzContent]="userProfile.email" nzCopyable></span>
            </nz-space-item>
            <nz-space-item>
                <i nz-icon nzType="mobile" nzTheme="twotone"></i>
                <span nz-typography [nzContent]="userProfile.personalPhone" nzCopyable></span>
            </nz-space-item>
        </nz-space>
    </div>
</div>
<nz-divider nzText="Change Password" nzOrientation="left"></nz-divider>
<app-change-password-form></app-change-password-form>