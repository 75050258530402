import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { ConfigState, ApplicationConfiguration, AuthService } from '@abp/ng.core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss'],
})
export class SiteLayoutComponent implements OnInit {
  @Select(ConfigState.getOne('currentUser'))
  currentUser$: Observable<ApplicationConfiguration.CurrentUser>;
  currentUser: ApplicationConfiguration.CurrentUser;

  isCollapsed = true;
  constructor(router: Router, private oAuthService: OAuthService, public authService: AuthService) {
    this.currentUser$.subscribe(c => {
      if (c?.userName) {
        const roles = c.roles.map(m => m.toLowerCase());
        if (roles.includes('customer')) {
          router.navigate(['/', 'portal']);
        } else if (roles.includes('vendor')) {
          router.navigate(['/', 'vendor']);
        } else {
          this.currentUser = c;
        }
      } else {
        this.authService.initLogin();
      }
    });
  }

  ngOnInit() {}

  isLoggedIn() {
    return this.oAuthService.hasValidAccessToken();
  }

  logOut() {
    this.authService.logout().subscribe(s => {
      this.authService.initLogin();
    });
  }
}
