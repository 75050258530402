import { AppMenuDto } from './app.model';

import { mappingMenus } from './menu.mappings.register';
import { reportMenus } from './menu.reports.register';
import { transactionMenus } from './menu.transactions.register';
import { masterMenus, adminMenu } from './menu.master.register';

import { RoutesService, eLayoutType, TreeNode, ABP } from '@abp/ng.core';
import { LocalizationService } from '@abp/ng.core';

// App Global State
export const appMenus = [
  ...masterMenus,
  ...mappingMenus,
  ...transactionMenus,
  ...reportMenus,
] as AppMenuDto[];

export let menus: TreeNode<ABP.Route>[];
export let visibleMenus: AppMenuDto[];

export const renderMenu = (routes: RoutesService, localizationService: LocalizationService) => {
  const mapMenuItems = (appMenuDtos: AppMenuDto[], module: string, parentName: string) => {
    const newAppMenuDtos = [] as AppMenuDto[];
    appMenuDtos.forEach((menu, index) => {
      const appMenu = {
        path: `/${menu.module || module}/` + menu.path,
        name: '::Menu:' + menu.path,
        parentName: parentName,
        requiredPolicy: menu.requiredPolicy,
        invisible: menu.invisible,
        iconClass: menu.iconClass,
      };
      (appMenu as any).order = index;
      newAppMenuDtos.push(appMenu);
    });
    return newAppMenuDtos;
  };

  const items = [
    ...mapMenuItems(masterMenus, 'x/pg', '::Menu:masterpages'),
    ...mapMenuItems(adminMenu, 'admin', '::Menu:admin'),
    ...mapMenuItems(mappingMenus, 'pages', '::Menu:mappings'),
    ...mapMenuItems(transactionMenus, 'pages', '::Menu:transactions'),
    ...mapMenuItems(reportMenus, 'reports', '::Menu:reports'),
  ];

  const menuItems = items.map(menu => ({
    path: menu.path,
    name: menu.name,
    iconClass: menu.iconClass,
    parentName: menu.parentName,
    requiredPolicy: menu.requiredPolicy || 'AccessDenied',
    layout: eLayoutType.application,
    invisible: menu.invisible,
    order: (menu as any).order,
  }));
  routes.add(menuItems);

  visibleMenus = [];
  const buildMenu = (children: TreeNode<ABP.Route>[]) => {
    const treeNodes = [] as TreeNode<ABP.Route>[];
    children.forEach(item => {
      item.invisible = !item.isLeaf && item.children.length <= 0;
      if (item.invisible) return;
      const treeNode = {
        name: localizationService.instant(item.name),
        path: item.path,
        iconClass: item.iconClass || 'right-circle',
        order: item.order,
      } as TreeNode<ABP.Route>;
      (treeNode as any).level = item.parentName ? 2 : 1;
      if (item.children.length > 0) {
        const treeChildren = buildMenu(item.children);
        treeNode.children = treeChildren.sort((a, b) => {
          return a.name < b.name ? -1 : 0;
        });
      }
      treeNodes.push(treeNode);
      if (treeNode.children == null) {
        visibleMenus.push({
          name: item.name,
          path: item.path,
          iconClass: item.iconClass,
        } as AppMenuDto);
      }
    });
    return treeNodes;
  };
  menus = buildMenu(routes.visible);
};

export const clearRenderedMenu = () => {
  menus = null;
};

export const addToVisited = (path: string) => {
  setTimeout(() => {
    const menuVisitedStr = localStorage.getItem('menuVisited');
    const menuVisited = menuVisitedStr ? (JSON.parse(menuVisitedStr) as any[]) : [];
    if (menuVisited.find(m => m.path === path)) {
      menuVisited.forEach(f => {
        if (f.path === path) {
          f.tick = new Date().getTime();
        }
      });
    } else {
      menuVisited.push({ path: path, tick: new Date().getTime() });
      if (menuVisited.length > 10) menuVisited.shift();
    }
    localStorage.setItem('menuVisited', JSON.stringify(menuVisited));
  }, 500);
};
