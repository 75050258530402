import { ValidationErrorComponent } from '@abp/ng.theme.basic';
import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-validation-error',
  template: `
    <div class="error-item" *ngFor="let error of abpErrors; trackBy: trackByFn">
      <i nz-icon nzType="warning" nzTheme="fill"></i>
      {{ error.message }}
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationComponent extends ValidationErrorComponent implements OnInit {
  @HostBinding('class.invalid-tooltip') someField = false;

  ngOnInit() {
    this.someField = true;
  }
}
