import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { AuditLog, PagedRequestDto, ImportResponse } from '../x-pg-model/x-pg.model';
import { DataRequestDto, EntitySchemaDto, PageRequestDto } from '../../../proxy/models';
import { IdNameDto } from '@proxy';

@Injectable({
  providedIn: 'root',
})
export class XPgService {
  apiName = 'Default';
  apiRootPath = 'api'; // api

  getSchema = (entityName: string) =>
    this.restService.request<any, EntitySchemaDto>(
      {
        method: 'GET',
        url: `/${this.apiRootPath}/app/${entityName}/schema`,
      },
      { apiName: this.apiName }
    );

  create = (entityName: string, input: any) => {
    const request: Rest.Request<null> = {
      method: 'POST',
      url: `/${this.apiRootPath}/app/${entityName}`,
      body: input,
    };
    return this.restService.request<any, any>(request, {
      apiName: this.apiName,
      skipHandleError: true,
    });
  };

  delete = (entityName: string, id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/${this.apiRootPath}/app/${entityName}/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (entityName: string, id: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/${this.apiRootPath}/app/${entityName}/${id}`,
      },
      { apiName: this.apiName }
    );

  getDropDownOptions = (entityName: string, input: DataRequestDto) =>
    this.restService.request<any, PagedResultDto<IdNameDto>>(
      {
        method: 'GET',
        url: `/${this.apiRootPath}/app/${entityName}/dropDownOptions`,
        params: {
          select: input.select,
          filter: input.filter,
          onlyActive: input.onlyActive,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getExpandById = (entityName: string, id: string) =>
    this.restService.request<any, any[]>(
      {
        method: 'GET',
        url: `/${this.apiRootPath}/app/${entityName}/${id}/expand`,
      },
      { apiName: this.apiName }
    );

  getList = (entityName: string, input: PagedRequestDto) => {
    return this.restService.request<any, PagedResultDto<any>>(
      {
        method: 'GET',
        url: `/${this.apiRootPath}/app/${entityName}`,
        params: input,
      },
      { apiName: this.apiName }
    );
  };

  getListWithOutDetails = (entityName: string, input: PagedRequestDto) => {
    return this.restService.request<any, PagedResultDto<any>>(
      {
        method: 'GET',
        url: `/${this.apiRootPath}/app/${entityName}/withOutDetails`,
        params: {
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          filter: input.filter,
          onlyActive: input.onlyActive,
          additionalData: input.additionalData,
        },
      },
      { apiName: this.apiName }
    );
  };

  getListExpand = (entityName: string, input: PagedRequestDto) =>
    this.restService.request<any, PagedResultDto<any>>(
      {
        method: 'GET',
        url: `/api/app/${entityName}/expand`,
        params: {
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          filter: input.filter,
        },
      },
      { apiName: this.apiName }
    );

  update = (entityName: string, id: string, input: any) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `/${this.apiRootPath}/app/${entityName}/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getAuditLogs = (input: any) =>
    this.restService.request<any, PagedResultDto<AuditLog>>(
      {
        method: 'GET',
        url: `/${this.apiRootPath}/app/auditLogs?filtering=${input.filtering}`,
      },
      { apiName: this.apiName }
    );

  getExportToExcel = (entityName: string) =>
    this.restService.request<any, number[]>(
      {
        method: 'GET',
        url: `/${this.apiRootPath}/app/${entityName}/exportToExcel`,
      },
      { apiName: this.apiName }
    );

  getExportToExcelByInput = (entityName: string, input: PageRequestDto) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/${this.apiRootPath}/app/${entityName}/exportToExcel`,
        params: {
          filter: input.filter,
          onlyActive: input.onlyActive,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  exportToCsv = (entityName: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/${this.apiRootPath}/app/${entityName}/exportToCsv`,
      },
      { apiName: this.apiName }
    );

  importFromCsv = (entityName: string, file: File) =>
    this.restService.request<any, ImportResponse[]>(
      {
        method: 'POST',
        url: `/${this.apiRootPath}/app/${entityName}/importFromCsv`,
        body: this.generateFormData(file),
      },
      { apiName: 'Default' }
    );

  generateFormData(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  }

  getExtraPropertiesById = (entityName: string, id: string) =>
    this.restService.request<any, Record<string, object>>(
      {
        method: 'GET',
        url: `/${this.apiRootPath}/app/${entityName}/${id}/extraProperties`,
      },
      { apiName: this.apiName }
    );

  getOneExtraPropertiesByIdAndKey = (entityName: string, id: string, key: string) =>
    this.restService.request<any, object>(
      {
        method: 'GET',
        url: `/${this.apiRootPath}/app/${entityName}/${id}/oneExtraProperties`,
        params: { key: key },
      },
      { apiName: this.apiName }
    );

  setExtraPropertiesByIdAndKeyAndValue = (
    entityName: string,
    id: string,
    key: string,
    value: string
  ) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/${this.apiRootPath}/app/${entityName}/${id}/setExtraProperties`,
        params: { key: key, value: value },
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) { }
}
