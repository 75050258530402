import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tenantName = localStorage.getItem("__tenant");
    const params = tenantName ? { headers: req.headers.set('__tenant', tenantName) } : {};
    const authReq = req.clone(params);
    return next.handle(authReq);
  }
}
