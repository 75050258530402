import { AfterViewInit, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Hour, PortDto, PortService, WeatherResponse } from '@proxy/ports';
import * as L from 'leaflet';
import { jsPDF } from 'jspdf';
import 'svg2pdf.js';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Chart from 'chart.js/auto';
@Component({
  selector: 'app-viewweather',
  templateUrl: './view-weather.component.html',
  styleUrls: ['./view-weather.component.scss'],
})
export class ViewWeatherComponent implements AfterViewInit {
  @Input() portId: string;
  canvas: any;
  ctx: any;
  private map: any;
  port: PortDto;
  weatherResponse: WeatherResponse;
  weatherViewModel = {} as { date: Hour[] };
  todayWeather: Hour = null;
  tabs = [];

  isVisible = false;
  tempTemp: ChartHeader[] = [];
  test: {};
  // For Line Chart

  weatherData: [];
  multi: any[];
  view: any[] = [650, 300];

  // options
  legend = true;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Date Time';
  yAxisLabel = 'Measurement';
  timeline = false;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
  };

  src: SafeResourceUrl;

  // For Line Chart
  constructor(
    private activatedRoute: ActivatedRoute,
    private portService: PortService,
    private router: Router,
    public datepipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {}

  ngAfterViewInit(): void {
    this.activatedRoute.params.subscribe(() => {
      const params = this.activatedRoute.snapshot?.parent?.params;
      this.portService.getExpandById(this.portId || params.id).subscribe(s => {
        this.port = s[0];
        const endDate = new Date();
        endDate.setHours(0, 0, 0, 0);
        endDate.setDate(endDate.getDate() + 4);
        this.portService
          .fetchWeatherInformationByIdAndUtcEndDate(this.port.id, endDate.toISOString())
          .subscribe(p => {
            if (p == null) return;
            this.weatherResponse = p;
            this.setUrl();
            this.weatherResponse.hours.forEach(hour => {
              if (this.todayWeather == null && this.isToday(hour.time)) {
                this.todayWeather = hour;
              }
              const date = hour.time.split('T')[0];
              if (date in this.weatherViewModel) {
                this.weatherViewModel[date].push(hour);
              } else {
                this.tabs.push({ name: date });
                this.weatherViewModel[date] = new Array(hour);
              }
            });
          });
      });
    });
  }

  buildDataSet(
    waveHeight: any[],
    wavePeriod: any[],
    swellHeight: any[],
    swellPeriod: any[],
    windSpeed: any[],
    windGust: any[]
  ) {
    return [
      {
        label: 'Wave Height',
        data: waveHeight,
        borderWidth: 1,
        pointRadius: 0,
        borderColor: '#96e698',
        backgroundColor: '#96e698',
        yAxisID: 'y',
      },
      {
        label: 'Swell Height',
        data: swellHeight,
        borderWidth: 1,
        pointRadius: 0,
        borderColor: '#4fa9dd',
        backgroundColor: '#4fa9dd',
        yAxisID: 'y',
      },
      {
        label: 'Wave Period',
        data: wavePeriod,
        borderWidth: 1,
        pointRadius: 0,
        borderColor: '#a473ed',
        backgroundColor: '#a473ed',
        yAxisID: 'y1',
      },
      {
        label: 'Swell Period',
        data: swellPeriod,
        borderWidth: 1,
        pointRadius: 0,
        borderColor: '#5ae5c9',
        backgroundColor: '#5ae5c9',
        yAxisID: 'y1',
      },
      {
        label: 'Wind Speed',
        data: windSpeed,
        borderWidth: 1,
        pointRadius: 0,
        borderColor: '#ee9b6d',
        backgroundColor: '#ee9b6d',
        yAxisID: 'y2',
      },
      {
        label: 'Wind Gust',
        data: windGust,
        borderWidth: 1,
        pointRadius: 0,
        borderColor: '#dcce55',
        backgroundColor: '#dcce55',
        yAxisID: 'y2',
      },
    ];
  }

  setConfig() {
    return {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      stacked: false,
      plugins: this.setPlugins(),
      scales: this.setAxes(),
    };
  }

  setPlugins() {
    return {
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: 'Weather Chart',
      },
    };
  }

  setAxes() {
    return {
      x: {
        title: {
          display: true,
          text: 'Date Time',
        },
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Height (m)',
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Period (s)',
        },
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Distance (m/s)',
        },
      },
    };
  }
  private initMap(): void {
    this.map = L.map('map', {
      center: [this.port.latitude || 0, this.port.longitude || 0],
      zoom: 10,
    });

    const tiles = L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="//www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);
  }

  navigateBack() {
    this.router.navigate(['x/pg', 'port']);
  }

  isToday(date: Date | string) {
    const today = new Date();
    date = typeof date === 'string' ? new Date(date) : date;
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return today.getHours() - date.getHours() <= 1;
    }
  }

  download() {
    // this.generateWeatherData();
    this.isVisible = true;
    setTimeout(() => {
      this.canvas = document.getElementById('myChart');
      this.ctx = this.canvas.getContext('2d');
      let myChart = new Chart(this.ctx, {
        type: 'line',
        data: this.buildChartData(),
        options: this.setConfig(),
      });
    }, 100);
  }

  weatherDownload() {
    const fileName = 'WeatherReport';
    const pdf = new jsPDF('p', 'pt', 'a4', false);
    pdf.html(document.getElementById('WeatherReport'), {
      image: {
        type: 'webp',
        quality: 1000,
      },
      html2canvas: {
        svgRendering: true,
      },
      callback: doc => {
        doc.setFillColor(0, 0, 0, 0);
        doc.save();
      },
      // callback: doc => {
      //   doc
      //     .svg(document.querySelector('canvas'), {
      //       x: 5,
      //       y: 440,
      //       width: 650,
      //       height: 300,
      //     })
      //     .then(() => {
      //       doc.save(fileName);
      //     });
      // },
      x: 0,
      y: 0,
    });
  }

  buildChartData() {
    const dateTime = [];
    const waveHeight = [];
    const wavePeriod = [];
    const swellHeight = [];
    const swellPeriod = [];
    const windSpeed = [];
    const windGust = [];
    this.tabs.forEach(tabs => {
      this.weatherViewModel[tabs.name].forEach(data => {
        dateTime.push(this.datepipe.transform(data.time, 'dd MMM HH:mm'));
        waveHeight.push(data.waveHeight.sg);
        wavePeriod.push(data.wavePeriod.sg);
        swellHeight.push(data.swellHeight.sg);
        swellPeriod.push(data.swellPeriod.sg);
        windSpeed.push(data.windSpeed.sg);
        windGust.push(data.gust.sg);
      });
    });
    return {
      labels: dateTime,
      datasets: this.buildDataSet(
        waveHeight,
        wavePeriod,
        swellHeight,
        swellPeriod,
        windSpeed,
        windGust
      ),
    };
  }

  setUrl() {
    const url = `//embed.windy.com/embed2.html?lat=${this.port.latitude}&lon=${this.port.longitude}&zoom=8&level=surface&overlay=wind&marker=true&location=coordinates&message=false`;
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

interface ChartHeader {
  name: string;
  series: ChartSeries[];
}
interface ChartSeries {
  name: string;
  value: number;
}
