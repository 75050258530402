import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portal-layout',
  templateUrl: './portal-layout.component.html',
  styleUrls: ['./portal-layout.component.scss'],
})
export class PortalLayoutComponent implements OnInit {
  isCollapsed = true;
  constructor() {}

  ngOnInit() {}
}
